import React from 'react';

import Image from '../components/Image';
import wrapInSection from '../components/wrapInSection';
import wrapInLayout from '../components/wrapInLayout';

const NotFound = () => (
  <div>
    <Image src="404.jpg" alt="Страница не найдена" />
    <h2 className="text-center">Такая страница не найдена :p</h2>
  </div>
);

export default wrapInLayout(wrapInSection(NotFound));
